import React from "react";
import {ReactComponent as Icon} from "../../static/Icons-Svg/Icon-Loading.svg";

export const Loader = props => {
    return(
        <div className="Loader">
            <div className="LoaderIcon">
                <Icon />
            </div>
        </div>
    )
};