import React, {Component} from 'react';
import SortimentCard from "./SortimentCard";
import {SortimentFilterInterfaceData} from "./SortimentFilterInterfaceData";
import {IconArrowRight, IconArrowLeft} from "../Icons/Icons";
import {Searchbar} from "./Searchbar";

const vinosPerPage = 18;

class SortimentView extends Component {

    constructor(props) {
        super(props);


        this.checkFilterOption = this.checkFilterOption.bind(this);
        this.handleFilterOption = this.handleFilterOption.bind(this);
        this.checkSortOption = this.checkSortOption.bind(this);
        this.handleSortOption = this.handleSortOption.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.checkFilter = this.checkFilter.bind(this);

        this.searchbarRef = React.createRef();

        this.state = {
            viewOptions: {
                weinsorte: [],
                bodega: [],
                region: [],
                sorting: "manual",
                searchStr: ""
            },
            vinosToRender: [],
            currentPage: 0,
        };

        this.state.vinosToRender = props.entries
        // this.state.totalPages = Math.round(this.state.filteredVinos.length / this.state.vinosPerPage)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.viewOptions !== prevState.viewOptions) {
            this.applyViewOptions()
        }
        if (this.state.currentPage !== prevState.currentPage) {
            window.scrollTo(0, 0);
        }
    }


    // Filter Interface Functionality

    // add or remove option from array
    handleFilterOption = (_categoryHandle, _optionId) => {
        let optionsArray = this.state.viewOptions[_categoryHandle];

        if (optionsArray.includes(_optionId)) {
            const index = optionsArray.indexOf(_optionId);
            if (index > -1) {
                optionsArray.splice(index, 1);
            }
        } else {
            optionsArray.push(_optionId)
        }
        this.setState({
            viewOptions: {
                ...this.state.viewOptions,
                [_categoryHandle]: optionsArray
            }
        })
    }

    // check if option is active
    checkFilterOption = (_categoryHandle, _optionId) => {
        const index = this.state.viewOptions[_categoryHandle].indexOf(_optionId);
        return index !== -1;
    }

    // replace sorting option
    handleSortOption = (_optionHandle) => {
        this.setState({
            viewOptions: {
                ...this.state.viewOptions,
                sorting: _optionHandle,
            }
        });
    }

    // check if this option is active
    checkSortOption = (_optionHandle) => {
        return this.state.viewOptions.sorting === _optionHandle;
    }

    // reset viewOptions to initial values
    clearFilters = () => {
        this.searchbarRef.current.value = "";
        this.setState({
            viewOptions: {
                weinsorte: [],
                bodega: [],
                region: [],
                sorting: "manual",
                searchStr: ""
            },
        })
    }

    // check if filters are set
    checkFilter = (_handle) => {
        const {viewOptions} = this.state;
        if (!_handle) {
            return (
                viewOptions.weinsorte.length
                + viewOptions.bodega.length
                + viewOptions.region.length
                + viewOptions.searchStr.length !== 0
                || viewOptions.sorting !== "manual"
            );
        } else if (_handle === "sorting") {
            return viewOptions.sorting !== "manual";
        } else {
            return viewOptions[_handle].length !== 0;
        }
    }

    applyViewOptions = () => {
        let cacheToRender = this.props.entries
        let vinosToRender
        const {searchStr} = this.state.viewOptions;

        // HANDLING THE FILTER
        // Check if any filter is selected
        if (this.state.viewOptions.weinsorte.length
            + this.state.viewOptions.bodega.length
            + this.state.viewOptions.region.length
            + searchStr.length > 0) {
            vinosToRender = cacheToRender.filter(entry => {
                    const filterWeinsorte = this.state.viewOptions.weinsorte
                    const filterBodega = this.state.viewOptions.bodega
                    const filterRegion = this.state.viewOptions.region

                    if (filterWeinsorte.length > 0 ? (filterWeinsorte.includes(entry.weinsorte[0].id)) : true) {
                        // ? if wensorte filter is selected -> Check if entry includes the weinsorte : else -> ignore weinfilter and continue
                        if (filterBodega.length > 0 ? (filterBodega.includes(entry.bodega[0].id)) : true) {
                            // ? if Bodega filter is selected -> Check if entry includes the Bodega : else -> ignore Bodega and continue
                            if (filterRegion.length > 0 ? (filterRegion.includes(entry.region[0].id)) : true) {
                                // ? if Region filter is selected -> Check if entry includes the Region : else -> ignore Region and continue
                                if (
                                    searchStr.toLowerCase().split(' ').every( _str => entry.title.toLowerCase().includes(_str) )
                                ) {
                                    return true
                                }
                            }
                        }
                    }
                    return false
                }
            )
        } else {
            vinosToRender = cacheToRender
        }

        // HANDLING THE SORTOPTION
        let sortOption = this.state.viewOptions.sorting
        // Check if it is manual
        if (sortOption !== 'manual') { // If any sorting option is selected sort vinosToRender
            vinosToRender = [...vinosToRender].sort((a, b) => {
                if (sortOption === 'name') {
                    return (a.title > b.title) ? 1 : -1
                } else if (sortOption === 'bodega') {
                    return (a.bodega[0].title > b.bodega[0].title) ? 1 : -1
                } else if (sortOption === 'region') {
                    return (a.region[0].title > b.region[0].title) ? 1 : -1
                } else if (sortOption === 'price') {
                    return (a.preisPrivat > b.preisPrivat) ? 1 : -1
                } else {
                    console.error(`Can't resolve the sorting option. In SortimentView.js`)
                    return null;
                }
            })
        }


        this.setState({
            vinosToRender,
            currentPage: 0,
        })
    }


    prevPage = () => {
        let currentPage = Number(this.state.currentPage)
        if (currentPage > 0) {
            this.setState({
                currentPage: currentPage - 1
            })
        }
    }

    nextPage = () => {
        let currentPage = Number(this.state.currentPage)
        const totalPages = Math.ceil(this.state.vinosToRender.length / vinosPerPage);

        if (currentPage < totalPages - 1) {
            this.setState({
                currentPage: currentPage + 1
            })
        }
    }

    setSearchStr(_str) {
        this.setState({
            viewOptions: {
                ...this.state.viewOptions,
                searchStr: _str
            }
        })
    }

    render() {
        // Always using the vinosToRender object for displaying
        const {currentPage} = this.state;
        const totalPages = Math.ceil(this.state.vinosToRender.length / vinosPerPage);
        const vinosRenderList = this.state.vinosToRender.slice(currentPage * vinosPerPage, currentPage * vinosPerPage + vinosPerPage);
        return (
            <div>

                <SortimentFilterInterfaceData
                    handleFilterOption={this.handleFilterOption}
                    checkFilterOption={this.checkFilterOption}
                    handleSortOption={this.handleSortOption}
                    checkSortOption={this.checkSortOption}
                    clearFilters={this.clearFilters}
                    checkFilter={this.checkFilter}
                />


                <div className={"SortimentView container"}>

                    <Searchbar
                        ref={this.searchbarRef}
                        setSearchStr={this.setSearchStr.bind(this)}
                    />

                    {
                        vinosRenderList.length < 1 ?
                            <div className="py-4 text-center">
                                Für diese Filtereinstellungen wurden keine Ergebnisse gefunden.
                            </div>
                            :
                            <div>
                                <div className="row">
                                    {
                                        vinosRenderList.map(
                                            entry =>
                                                <SortimentCard
                                                    key={entry.id}
                                                    {...entry}
                                                />
                                        )
                                    }
                                </div>
                            </div>
                    }

                    {
                        totalPages > 1 &&
                        <div className="SortimentView-PaginationControls">
                            <button
                                className={`button-reset button-dark aktiv-regular button-square ${this.state.currentPage === 0 ? "disabled" : ""} mr-3`}
                                onClick={this.prevPage}>
                                <IconArrowLeft/>
                            </button>
                            <span className={"aktiv-regular"}>
                                Seite {Number(this.state.currentPage) + 1} von {totalPages}
                            </span>
                            <button
                                className={`button-reset button-square button-dark aktiv-regular  ${this.state.currentPage === totalPages - 1 ? "disabled" : ""} ml-3`}
                                onClick={this.nextPage}>
                                <IconArrowRight/>
                            </button>
                        </div>
                    }

                </div>
            </div>
        );
    }
}

export default SortimentView;

