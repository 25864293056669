import React from 'react';
import {FavoritenGeneralAction} from "./FavoritenGeneralAction";
import {SubNav} from "../Navigation/SubNav";
import {WeinData} from "../Wein/WeinData";

export const FavoritenView = props => {
    if (props.favorites.length > 0) {
        return (
            <div>
                <SubNav/>
                <div className={"FavoritenView container"}>
                    { props.favorites.map(entry =>
                        <WeinData
                            key={entry}
                            id={entry}
                        />) }
                    <FavoritenGeneralAction />
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <SubNav/>
                <div className={"FavoritenView container"}><h2>Sie haben noch keine Produkte als Favoriten markiert.</h2></div>
            </div>
        )
    }
}
