import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import WeinlandView from "../components/Weinland/WeinlandView";
import {Loader} from "../components/Icons/Loader";
import {CSSTransition} from "react-transition-group";
import {SimpleLoadIndicator} from "../components/SimpleLoadIndicator/SimpleLoadIndicator";

const GET_GREETING = gql`
    query {
        bodegas: categories ( group: "bodega" ) {
            title
            id
            uri
            ... on bodega_Category{
                region {
                    id
                }
            }
        }
        regions: categories ( group: "region" ) {
            title
            id
            uri
            slug
        }
        weinland: entry(section:"weinland"){
            ... on weinland_weinland_Entry{
                weinlandText
            }
        }
    }
`;

export const Weinland = props => {

    const {section} = props.match.params;

    const {loading, error, data} = useQuery(GET_GREETING, {
        variables: {section: section},
    });


    return (
        <div>

            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SimpleLoadIndicator />
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SimpleLoadIndicator error />
            </CSSTransition>

            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <WeinlandView {...data} />
            </CSSTransition>

        </div>
    );

};
