import React from "react";
import {NavLink} from "react-router-dom";
import {FavoritesContext} from "../Providers/favorites-context";
import {CartContext} from "../Providers/cart-context";
import {DesktopNav} from "./DesktopNav";
import {MobileNav} from "./MobileNav";

export const SubNav = () => {
    return (
        <FavoritesContext.Consumer>
            {({favorites}) =>
                <CartContext.Consumer>
                    {({cart}) => {
                        return (
                            <div className={"SubNav text-center aktiv-bold"}>
                                <NavLink to={"/favoriten"} className="SubNav-Item">
                                    Favoriten <small className="badge badge-warning rounded-pill">{favorites.length}</small>
                                </NavLink>
                                <NavLink to={"/warenkorb"} className="SubNav-Item">
                                    Warenkorb <small className="badge badge-warning rounded-pill">{cart.length}</small>
                                </NavLink>
                            </div>

                        )
                    }
                    }
                </CartContext.Consumer>
            }
        </FavoritesContext.Consumer>


    )
}
