import React from "react";

export const Ueberuns = props => {
    const {entry} = props;
    return (
        <div className="Ueberuns">

            <div className="UeberunsBilder">
                {entry.unsBilder.map(
                    img =>
                        <img
                            key={img.url}
                            src={img.url}
                            width={img.width}
                            height={img.height}
                            alt={img.title}

                        />
                )}
            </div>


            <div className="UeberunsBody container">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                        <h1>{entry.unsUeberschrift}</h1>
                        <div className="redactor-body fliesstext-gross" dangerouslySetInnerHTML={{__html: entry.unsText}}/>
                    </div>
                </div>
            </div>

            <div className="UnsTeam container">
                <h1 className="headline-1 text-center">Team</h1>
                {
                    entry.team.map((block, index) =>
                        <div className="row UnsTeam-Row" key={block.id}>
                            <div className="col-12 col-md-6">
                                {block.bild.map(img => <img key={img.url} src={img.url} alt={img.title}
                                                            width={img.width} height={img.height}/>)}
                            </div>
                            <div
                                className={`col-12 col-md-6 UnsTeam-Text text-center ${index % 2 === 0 ? "order-md-first" : ""}`}>
                                <div className="UnsTeam-TextContent">
                                    <h2>{block.mitgliedName}</h2>
                                    <div dangerouslySetInnerHTML={{__html: block.text}} className="redactor-body fliesstext-klein"/>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="LadenBilder container">
                <h1 className="headline-1 text-center">Unser Laden</h1>
                {entry.ladenBilder.map(
                    img =>
                        <img
                            key={img.url}
                            src={img.url}
                            width={img.width}
                            height={img.height}
                            alt={img.title}
                            className="my-4"
                        />
                )}
            </div>

            <div className="UeberunsPartner container">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                        <div className="redactor-body fliesstext-gross" dangerouslySetInnerHTML={{__html: entry.partner}}/>
                    </div>
                </div>
            </div>


        </div>
    )
}