import React from "react";
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {IconArrowRight, IconBodega, IconRegion} from "../../Icons/Icons";
import {Link} from 'react-router-dom';
import {SimpleLoadIndicator} from "../../SimpleLoadIndicator/SimpleLoadIndicator";
import {CSSTransition} from "react-transition-group";

const DATA_QUERY = gql`
    query ( $id:[QueryArgument] ) {
        category ( id: $id ) {
            title
            id
            uri
            ... on bodega_Category{
                bodegaText
                region {
                    title
                    id
                    uri
                }
                bodegaBilder {
                    id
                    url @transform( handle:"portraitImages" )
                }
                bodegaFakten {
                    bezeichnung
                    info
                }
            }
        }
    }
`;


export const BodegaPortrait = props => {


    const catId = props.match.params.slug.split("-")[0];
    const {loading, error, data} = useQuery(
        DATA_QUERY,
        {
            variables: {id: catId},
        }
    );

    if (loading) return <SimpleLoadIndicator/>;
    if (error) return <SimpleLoadIndicator error />;

    // Check if data is returned
    if (data) {
        return (
            <div className="Portrait container">


                {/* HEADER */}
                <div className={"SubHeader"}>

                    {/* Icon */}
                    <div className="SubHeader-Icon">
                        <IconBodega block/>
                    </div>

                    {/* Headline */}
                    <h1>
                        {data.category.title}
                    </h1>

                    {/* Link to Region */}
                    {
                        data.category.region.length ?
                            <div className={"HeaderLink"}>
                                <Link to={`/${data.category.region[0].uri}`}>
                                    {data.category.region[0].title} <IconArrowRight/>
                                </Link>
                            </div>
                            : null
                    }

                </div>


                {/* FAKTEN & TEXT */}
                <div className={"SubContent row"}>

                    {
                        // Check if there are any Facts
                        data.category.bodegaFakten &&
                        // If some facts are defined return them
                        <div className={"SubFacts col-12 col-md-4 offset-lg-1"}>
                            {
                                // Display each Fact
                                data.category.bodegaFakten.map(entry =>
                                    <div key={entry.bezeichnung} className={"FaktenZeile"}>
                                        <div className={"FaktenHead aktiv-bold"}>{entry.bezeichnung}</div>
                                        {/* TODO: Wie setzt du links? mit 'dangerouslySetInnerHTML? Oder so eine URLify funtion schreiben oder »npm urlify«? */}
                                        <div className={"FaktenInfo aktiv-regular"}>{entry.info}</div>
                                    </div>
                                )
                            }
                        </div>
                    }


                    {
                        // Check if there is any text
                        data.category[props.isRegion ? 'regionText' : 'bodegaText']
                            ?
                            // Display text if defined
                            <div
                                className={"SubText col-12 col-md-8 col-lg-6 fliesstext-gross"}
                                dangerouslySetInnerHTML={{__html: data.category[props.isRegion ? 'regionText' : 'bodegaText']}}
                            />
                            :
                            null
                    }

                </div>


                {/* BILDER */}
                {
                    // CHECK IF ANY IMAGES ARE DEFINED
                    data.category.bodegaBilder &&
                    <div className={"SubImages col"}>
                        {
                            data.category.bodegaBilder.map(img =>
                                <img key={img.id} src={img.url}/>
                            )
                        }
                    </div>
                }

                {/* LINNKS TO ALL BODEGAS   */}
                {
                    props.isRegion
                        ?
                        <div className={"bodegaLinks"}>
                            {
                                props.bodegasOfRegion.length > 0
                                    ?
                                    <h3>Alle Bodegas der Region</h3>
                                    :
                                    null
                            }
                            {
                                props.bodegasOfRegion.map(bodega =>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.goToURL(`/${bodega.uri}`)
                                        }}
                                        key={bodega.id}
                                    >
                                        {bodega.title}
                                    </div>
                                )
                            }
                        </div>
                        :
                        null
                }

            </div>
        );
    } else {
        return <div>Wir konnten deine Anfrage leider nicht Verarbeiten.</div>
    }
};
