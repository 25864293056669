import React, {forwardRef} from 'react';

export const Searchbar = forwardRef((props, ref) => {
    return (
        <div className="SortimentSearchbar">

            <input
                ref={ref}
                type={"text"}
                placeholder={"Nach Produkt suchen"}
                className="aktiv-regular"
                onChange={(event)=>{
                    props.setSearchStr(event.target.value)
                }}
            />

        </div>
    )
});