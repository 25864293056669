import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import SortimentView from "../components/SortimentView/SortimentView";
import {Loader} from "../components/Icons/Loader";
import {CSSTransition} from "react-transition-group";

const GET_GREETING = gql`
    query {
        entries (section: "sortiment", orderBy: "lft" ) {
            title
            slug
            id
            uri
            ... on sortiment_sortiment_Entry{
                bodega {
                    title
                    slug
                    id
                }
                region{
                    title
                    slug
                    id
                }
                traubensorte{
                    title
                    slug
                }
                weinsorte{
                    title
                    slug
                    id
                }
                bild{
                    url @transform( handle:"sortimentPreview" )
                    title
                }
                jahr
                fuellmenge
                preisPrivat
                preisGastro
                beschreibung
                weinTags{
                    title
                }
            }
        }
    }
`;

export const Sortiment = props => {

    const {section} = props.match.params;

    const {loading, error, data} = useQuery(GET_GREETING, {
        variables: {section: section},
    });


    return (
        <div>

            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <Loader/>
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <p>Error </p>
            </CSSTransition>

            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SortimentView {...data} />
            </CSSTransition>

        </div>
    );

};
