import React from 'react';
import {SubNav} from "../Navigation/SubNav";
import {WarenkorbGenralAction} from "./WarenkorbGenralAction";
import {WarenkorbTotal} from "./WarenkorbTotal";
import {WeinData} from "../Wein/WeinData";

export const WarenkorbView = props => {
    if (props.cart.length > 0) {
        return (
            <div>
                <SubNav/>
                <div className={"WarenkorbView container"}>
                    {
                        props.cart.map(wein => {
                            return (
                                <WeinData
                                key={wein.vinoId}
                                {...wein}
                            />)
                        })}
                    <WarenkorbTotal {...props} />
                    <WarenkorbGenralAction {...props}/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <SubNav/>
                <div className={"WarenkorbView container"}>
                    <h2>
                        Sie haben noch keine Produkte zum Warenkorb hinzugefügt.
                    </h2>
                </div>
            </div>
        )
    }
}
