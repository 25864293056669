import React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from 'graphql-tag';
import {WeinRow} from "./WeinRow";
import {CartContext} from "../Providers/cart-context";
import {SimpleLoadIndicator} from "../SimpleLoadIndicator/SimpleLoadIndicator";

const QUERY = gql`
    query ( $id:[QueryArgument]) {
        entry ( id: $id, ) {
            title
            slug
            id
            uri
            ... on sortiment_sortiment_Entry{
                bodega {
                    title
#                    slug
                }
                region{
                    title
#                    slug
                }
                traubensorte{
                    title
#                    slug
                }
                weinsorte{
                    title
#                    slug
                }
                bild{
                    url @transform( handle:"sortimentPreview" )
                    title
                }
                jahr
                fuellmenge
                preisPrivat
                preisGastro
                weinTags{
                    title
                }
            }
        }
    }
`;

export const WeinData = props => {
    let vinoId
    // Checking if from cart or favorites
    if (props.vinoId) {
        vinoId = props.vinoId
    } else {
        vinoId = props.id
    }

    // Pulling Data by current Cookies
    const {loading, error, data} = useQuery(QUERY, {
        // GraphQL Accepts Array
        variables: {id: vinoId},
    });

    if (loading) return <SimpleLoadIndicator />;
    if (error) return <SimpleLoadIndicator error />;

    return (
        <CartContext.Consumer>
            {({removeFromCart}) => {
                return (<WeinRow {...data} removeFromCart={removeFromCart} vinoId={vinoId}/>);
            }}
        </CartContext.Consumer>
    )

};
