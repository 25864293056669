import React, {Component} from 'react';
import {IconSchloss} from "../Icons/Icons";

class GastroLogin extends Component {

    state = {
        startLogin: false,
        login: 'hispania',
        wrongPassword: false
    }

    constructor(props) {
        super(props);
        this.passwortField = React.createRef()
    }

    startLogin = (bool) => {
        this.setState({startLogin: bool})
    }

    cancelLogin = () => {
        this.setState({startLogin: false})
        this.setState({wrongPassword: false})
    }

    handleLogin = () => {
        if (this.props.gastro) {
            this.props.setGastroBool(0)
        } else {
            this.setState({startLogin: true})
        }
    }

    checkPassword = () => {
        if (this.state.login === this.passwortField.current.value) {
            // If password is correct
            this.setState({wrongPassword: false})
            this.setState({startLogin: false})
            this.props.setGastroBool(1)
        } else {
            // if password is wrong
            this.setState({wrongPassword: true})
        }
    }

    render() {
        return (
            <div className={`GastroLogin aktiv-regular
                ${this.props.gastro ? 'loggedIn' : 'loggedOut'}
                ${ this.state.startLogin ? 'startLogin' : null}
            `}>
                    <div
                        className={`toggleSwitch`}
                        onClick={this.handleLogin}
                    >
                        <div className={`innerSwitch ${ this.state.wrongPassword ? 'shake' : null }`}>
                            { this.state.startLogin ? <IconSchloss block={"true"} /> : null }
                        </div>
                    </div>
                    <form onSubmit={(e
                    ) => {
                        e.preventDefault();
                        this.checkPassword(e)
                    }}
                    >
                        {
                            this.state.startLogin
                            ?
                            <span>
                                <input type={"text"}
                                       className={this.state.wrongPassword ? 'wrong' : null }
                                       placeholder={"Passwort"}
                                       ref={this.passwortField}
                                />
                                <button className={"btn btn-dark aktiv-regular"} onSubmit={(e
                                ) => {
                                    e.preventDefault();
                                    this.checkPassword(e)
                                }}>Submit</button>
                                <button className={"btn btn-dark aktiv-regular"} onClick={this.cancelLogin}>Cancel</button>
                            </span>
                            :
                            null
                        }
                    </form>
                    {/*{Number(this.props.gastro) === 1*/}
                    {/*    ? // If already logged in*/}
                    {/*    <div>*/}
                    {/*        Eingeloggt als Gastro.*/}
                    {/*        <button className={"btn btn-dark aktiv-regular"} onClick={this.logOut}>LogOut</button>*/}
                    {/*    </div>*/}
                    {/*    : // If not logged in*/}
                    {/*    <div>*/}
                    {/*        {*/}
                    {/*            this.state.startLogin*/}
                    {/*                ?*/}
                    {/*                <form onSubmit={(e*/}
                    {/*                ) => {*/}
                    {/*                    e.preventDefault();*/}
                    {/*                    this.checkPassword(e)*/}
                    {/*                }}*/}
                    {/*                >*/}
                    {/*                    <input type={"text"}*/}
                    {/*                           className={"LogIn-Input"}*/}
                    {/*                           placeholder={"Passwort"}*/}
                    {/*                           ref={this.passwortField}*/}
                    {/*                    />*/}
                    {/*                    {this.state.wrongPassword ? <div>Dein Passwort ist falsch.</div> : null}*/}
                    {/*                    <button className={"btn btn-dark aktiv-regular"}>*/}
                    {/*                        Submit*/}
                    {/*                    </button>*/}
                    {/*                    <button  className={"btn btn-dark aktiv-regular"} onClick={this.cancelLogin}>*/}
                    {/*                        Cancel*/}
                    {/*                    </button>*/}
                    {/*                </form>*/}
                    {/*                :*/}
                    {/*                <a*/}
                    {/*                    href=""*/}
                    {/*                    className={"startLogin"}*/}
                    {/*                    onClick={(event) => {*/}
                    {/*                        event.preventDefault();*/}
                    {/*                        this.startLogin(true);*/}
                    {/*                    }}>*/}
                    {/*                    Gastro Login*/}
                    {/*                </a>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*}*/}
            </div>
        );
    }
}

export default GastroLogin;
