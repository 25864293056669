import React from "react";
import {IconBodega, IconRegion, IconTrauben, IconWeinsorte} from "../Icons/Icons";
import {CartContext} from "../Providers/cart-context";
import {transformPrice} from "../../utils/transform-price";

export const WeinMeta = props => {
    return (
        <div className="WeinMeta ">

            <div className="WeinMeta-Body sans-regular aktiv-regular">
                <ul>
                    {props.weinsorte[0] &&
                    <li>
                        <IconWeinsorte block />
                        <div>
                            {props.weinsorte.map((item, index) => {
                                if (index + 1 < props.weinsorte.length) {
                                    return item.title + ", "
                                } else {
                                    return item.title;
                                }
                            })}
                        </div>
                    </li>
                    }
                    {props.bodega[0] &&
                    <li>
                        <IconBodega block />
                        <div>
                            {props.bodega.map((item, index) => {
                                if (index + 1 < props.bodega.length) {
                                    return item.title + ", "
                                } else {
                                    return item.title;
                                }
                            })}
                        </div>
                    </li>
                    }
                    {props.region[0] &&
                    <li>
                        <IconRegion block />
                        <div>
                            {props.region.map((item, index) => {
                                if (index + 1 < props.region.length) {
                                    return item.title + ", "
                                } else {
                                    return item.title;
                                }
                            })}
                        </div>
                    </li>
                    }
                    {props.traubensorte[0] &&
                    <li>
                        <IconTrauben block />
                        <div>
                            {props.traubensorte.map((item, index) => {
                                if (index + 1 < props.traubensorte.length) {
                                    return item.title + ", "
                                } else {
                                    return item.title;
                                }
                            })}
                        </div>
                    </li>
                    }
                </ul>
            </div>

            <CartContext.Consumer>
                {({gastro}) => {
                    return (
                        <div className="WeinMeta-Footer sans-regular aktiv-regular">
                            {props.jahr && props.jahr + "  ●  "}{props.fuellmenge && props.fuellmenge + " cl ● "}
                            {gastro
                                ? props.preisGastro && "CHF " + transformPrice(props.preisGastro)
                                : props.preisPrivat && "CHF " + transformPrice(props.preisPrivat)
                            }
                        </div>
                    )
                }}
            </CartContext.Consumer>


        </div>
    )
};
