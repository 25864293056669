import React from 'react';
import {FavoritenView} from "../components/FavoritenView/FavoritenView";
import {FavoritesContext} from "../components/Providers/favorites-context";

export const Favoriten = props => {
    return (
        <FavoritesContext.Consumer>
            { ({favorites}) => {
                return (
                    <div>
                        <FavoritenView {...props} favorites={favorites}/>
                    </div>
                )
            }}
        </FavoritesContext.Consumer>
    )
}
