import React, {Component} from 'react';
import {CartContext} from "./cart-context";
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from 'react-cookie';
const DATE_EXPIRE = new Date(2120, 5, 24, 18, 30)

class CartProvider extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);
        const { cookies } = props

        this.state = {
            cart: cookies.get('cart') || [],
            gastro: cookies.get('gastro') === 1 ? true : false || false,
            addToCart: this.addToCart.bind(this),
            checkCart: this.checkCart.bind(this),
            removeFromCart: this.removeFromCart.bind(this),
            clearCart: this.clearCart.bind(this),
            getVinoFromCart: this.getVinoFromCart.bind(this),
            setVinoAmount: this.setVinoAmount.bind(this),
            addFavoritesToCart: this.addFavoritesToCart.bind(this),
            setGastroBool: this.setGastroBool.bind(this)
        }
    }

    componentDidUpdate( prevProps, prevState ) {
        if(prevState !== this.state){
            this.mountStateToCookie()
        }
    }

    // mount state to cookie
    mountStateToCookie(){
        const { cookies } = this.props
        cookies.set('cart', this.state.cart, { path: '/', expires: DATE_EXPIRE })
    }

    setGastroBool(bool) {
        this.setState({gastro: bool === 1})
        const { cookies } = this.props
        cookies.set('gastro', bool, { path: '/', expires: DATE_EXPIRE })
    }

    addToCart(id, amount) {
        let currentCart = this.state.cart

        // Check if id exists in currentCart
        if (currentCart.some(entry => entry.vinoId === id)) {
            currentCart.find(entry => entry.vinoId === id).amount = amount
        } else {
            // if not existand push new array
            currentCart.push({vinoId: id, amount: amount})
        }

        // Pushing the current Values to the currentCartArray only if the amount is larger than 0
        // Setting the new Cart Cookie
        this.setState({cart: currentCart})
    }

    // check if id is in favorits
    checkCart(id){
        // return boolean
        let currentCart = this.state.cart
        currentCart = currentCart.filter(entry => entry.vinoId === id)
        return currentCart.length > 0
    }

    removeFromCart(id) {
        let currentCart = this.state.cart
        // Checking if there is already an Entry for this Vino. If so remove it from the Array
        currentCart = currentCart.filter(entry => entry.vinoId !== id)
        // Setting the new Cart
        this.setState({cart: currentCart})
    }

    clearCart() {
        this.setState({cart: []})
    }

    getVinoFromCart(id) {
        let currentCart = this.state.cart
        currentCart = currentCart.filter(entry => entry.vinoId === id)
        return currentCart[0]
    }

    setVinoAmount(id, e) {
        let currentCart = this.state.cart
        // If value is lower than 0 or 0 set 1
        let value = e.target.value > 0 ? e.target.value : 1

        if (currentCart.some(entry => entry.vinoId === id)) {
            currentCart.find(entry => entry.vinoId === id).amount = value
        } else {
            // if not existand push new array
            currentCart.push({vinoId: id, amount: value })
        }

        this.setState({cart: currentCart})
    }

    addFavoritesToCart(favorites) {
        let currentCart = this.state.cart
        favorites.map(id => {
            // Check if already existing
            let existingIds = []
            currentCart.map(cart => existingIds.push(cart.vinoId))
            if (!existingIds.includes(id)) {
                currentCart.push({vinoId: id, amount: 1})
            }
        })
        this.setState({cart: currentCart})
    }

    render() {
        return (
            <CartContext.Provider value={this.state}>
                {this.props.children}
            </CartContext.Provider>
        );
    }
}

export default withCookies(CartProvider);
