import React from 'react';

export const WeinlandIntro = props => (
    <div className={"Weinland-Description container"}>

        <h1>Das Weinland Spanien</h1>

        <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
                <div
                    className="fliesstext-klein"
                    dangerouslySetInnerHTML={{ __html: props.text }}
                />
            </div>
        </div>

    </div>
);