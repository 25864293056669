import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import GastroLogin from "./GastroLogin";
import {CartContext} from "../Providers/cart-context";

class PageFooter extends Component {
    render() {
        return (
            <div className={"PageFooter"}>


                <div className="PageFooter-Content container text-center text-sm-left">
                    <div className="row aktiv-regular">

                        <div className="col-12 col-sm-6 col-lg-3 mb-4">
                            <h6 className="aktiv-bold">Kontakt</h6>
                            <p dangerouslySetInnerHTML={{__html: window.Craft.kontakt}} className="mb-0"/>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 mb-4">
                            <h6 className="aktiv-bold">Öffnungszeiten</h6>
                            <p dangerouslySetInnerHTML={{__html: window.Craft.oeffnungszeiten}} className="mb-0"/>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 mb-4">
                            <h6 className="aktiv-bold">Newsletter</h6>
                            <p>
                                <a href={window.Craft.newsletterAnmelungUrl} target="_blank"  className="">Abonnieren</a>
                            </p>

                            <h6 className="aktiv-bold">Social Media</h6>
                            <ul className="mb-0">
                                {window.Craft.socialMedia.map(
                                    (media, index) =>
                                        <li key={media.url + index}><a href={media.url}>{media.name}</a></li>
                                )}
                            </ul>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 mb-4">
                            <h6 className="aktiv-bold">Datenschutz</h6>
                            <ul className="mb-0">
                                <li><NavLink to={"/agb"}>AGB</NavLink></li>
                                <li><NavLink to={"/datenschutz"}>Datenschutz</NavLink></li>
                                <li><NavLink to={"/impressum"}>Impressum</NavLink></li>
                            </ul>

                            <h6 className="aktiv-bold mt-3">Gastronomie</h6>
                            <CartContext.Consumer>
                                {({gastro, setGastroBool}) => {
                                    return (
                                        <GastroLogin setGastroBool={setGastroBool} gastro={gastro}/>
                                    )
                                }}
                            </CartContext.Consumer>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default PageFooter;
