import React, {Component} from 'react';
import {FavoritesContext} from "../Providers/favorites-context";
import {CartContext} from "../Providers/cart-context";
import {DesktopNav} from "./DesktopNav";
import {MobileNav} from "./MobileNav";

class PageHeader extends Component {
    render() {
        return (
            <FavoritesContext.Consumer>
                {({favorites}) =>
                    <CartContext.Consumer>
                        {({cart}) => {
                            return (
                                <div className={"Navigation"}>
                                    <DesktopNav favorites={favorites} cart={cart} />
                                    <MobileNav favorites={favorites} cart={cart} />
                                </div>
                            )
                        }
                        }
                    </CartContext.Consumer>
                }
            </FavoritesContext.Consumer>
        );
    }
}

export default PageHeader;
