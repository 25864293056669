import React from "react";
import {Link} from "react-router-dom";

const BestellungSuccess = props => {
    return (
            <div className="PageContent">
                <div className="container">

                    <h1 className={"text-center mb-5"}>Vielen Dank!</h1>
                    <p className={"text-center fliesstext-klein"}>
                        Sie erhalten in Kürze eine automatische Bestätigung per E-Mail. Wir werden uns anschliessend
                        persönlich bei Ihnen melden.
                    </p>
                    <p className={"text-center fliesstext-klein"}>
                        Sollten Sie keine Bestätigungs-E-Mail in Ihrem Postfach oder ihrem Spam vorfinden, wenden Sie
                        sich bitte an   <a href="mailto:mail@cavahispania.ch">mail@cavahispania.ch</a>.
                    </p>
                    <div className="text-center mt-5">
                        <Link to="/sortiment" className="btn btn-dark aktiv-regular">Weiterstöbern</Link>
                    </div>

                </div>
            </div>
    );
};

export default BestellungSuccess;