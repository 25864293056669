import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import React from "react";
import {FilterInterface} from "./SortimentFilterInterface/FilterInterface";

const QUERY = gql`
    query {
        weinsorte: categories ( group: "weinsorten", orderBy: "lft" ) {
            id
            title
        }
        bodega: categories ( group: "bodega", orderBy: "lft" ) {
            id
            title
        }
        region: categories (group:"region", orderBy: "lft"){
            id
            title
        }
    }
`;

export const SortimentFilterInterfaceData = props => {
    const {loading, error, data} = useQuery(QUERY, {});
    const sorting = [
        {
            handle: 'name',
            title: "Name",
        },
        {
            handle: 'bodega',
            title: "Bodega",
        },
        {
            handle: 'region',
            title: "Region",
        },
        {
            handle: 'price',
            title: "Preis",
        },
    ];
    if (loading) return <p>Loading ...</p>;
    if (error) return <p>Error </p>;
    return (
        <FilterInterface
            {...data}
            {...props}
            sorting={sorting}
            />
    );
};
