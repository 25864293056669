import React, {Component} from "react";
import BestellungSuccess from "./BestellungSuccess";
import axios from 'axios';
import {siteUrl} from "../../constants/siteUrl";
import {Link} from "react-router-dom";
import {Lieferkosten} from "./Lieferkosten";

class BestellungsanfrageView extends Component {

    state = {
        cart: [],
        emailIncomplete: false,
        nameIncomplete: false,
        firstNameIncomplete: false,
        addressIncomplete: false,
        cityIncomplete: false,
        invalidEmail: false,
        somethingWrong: false,
        success: false,
        sending: false
    }

    constructor(props) {
        super(props);
        this.state.cart = props.cart
        this.refName = React.createRef();
        this.refFirstName = React.createRef();
        this.refAddress = React.createRef();
        this.refCity = React.createRef();
        this.refFirma = React.createRef();
        this.refEmail = React.createRef();
        this.refTel = React.createRef();
        this.refMessage = React.createRef();

        this.submitData = this.submitData.bind(this)
    }

    async submitData(event) {
        this.setState({sending: true})
        // First check if form is complete
        let email = this.refEmail.current.value
        let emailTest = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // Creating local boolean for email
        let emailComplete = false
        if (email.replace(/\s/g, '').length === 0) {
            this.setState({sending: false})
            this.setState({emailIncomplete: true})
        } else {
            // If something is entered remove warning
            this.setState({emailIncomplete: false})
            // Check if email is valid
            if (emailTest.test(email)) {
                this.setState({invalidEmail: false})
                emailComplete = true
            } else {
                this.setState({sending: false})
                this.setState({invalidEmail: true})
            }
        }
        let name = this.refName.current.value
        // Creating local boolean for name
        let nameComplete = false
        if (name.replace(/\s/g, '').length === 0) {
            this.setState({sending: false})
            this.setState({nameIncomplete: true})
        } else {
            this.setState({nameIncomplete: false})
            nameComplete = true
        }

        let firstName = this.refFirstName.current.value
        // Creating local boolean for firstName
        let firstNameComplete = false
        if (firstName.replace(/\s/g, '').length === 0) {
            this.setState({sending: false})
            this.setState({firstNameIncomplete: true})
        } else {
            this.setState({firstNameIncomplete: false})
            firstNameComplete = true
        }

        let address = this.refAddress.current.value
        // Creating local boolean for address
        let addressComplete = false
        if (address.replace(/\s/g, '').length === 0) {
            this.setState({sending: false})
            this.setState({addressIncomplete: true})
        } else {
            this.setState({addressIncomplete: false})
            addressComplete = true
        }

        let city = this.refCity.current.value
        // Creating local boolean for city
        let cityComplete = false
        if (city.replace(/\s/g, '').length === 0) {
            this.setState({sending: false})
            this.setState({cityIncomplete: true})
        } else {
            this.setState({cityIncomplete: false})
            cityComplete = true
        }


        if (nameComplete && emailComplete) {
            // SENDING DATA TO SERVER
            let response = await this.sendToServer({
                sectionId: "1",
                sectionHandle: "cavaAnfragen",
                authorId: "autorUid",
                siteId: "1",
                slug: "anfrage",
                enabled: true,
                clientInfo: {
                    clientIstGastro: false,
                    clientName: name,
                    clientFirstname: firstName,
                    clientAddress: address,
                    clientCity: city,
                    clientFirma: this.refFirma.current.value,
                    clientEmail: email,
                    clientPhone: this.refTel.current.value,
                    clientGastro: this.props.gastro,
                    clientMessage: this.refMessage.current.value
                },
                clientCart: this.state.cart
            });

            // checking response
            if (response) {
                // TODO: Response
                this.setState({sending: false})
                this.setState({success: true})
            } else {
                this.setState({sending: false})
                this.setState({somethingWrong: true})
            }
        }
    }

    sendToServer = (data) => {
        // TODO: Test connection to Server
        return axios
            .post(
                `${siteUrl}/actions/cava-hispania-plugin/carthandler`,
                data
            ).then(
                response => response,
                error => error,
            );
    }

    render() {
        if (!this.state.success) {
            return (
                <div className="BestellungsanfrageView">
                    <div className="Intro">
                        <div className="container">
                            <h1 className={"text-center"}>Kontaktdaten</h1>
                            <p className={"text-center fliesstext-klein"}>Bitte teilen Sie uns Ihre Kontaktdaten mit.
                                Wir werden uns in Kürze bei Ihnen melden.</p>
                        </div>
                        <Lieferkosten />
                    </div>

                    <div className={"BestellungsanfrageForm container"}>
                        <div className="row">
                            <div className="col-12 col-md-6 offset-md-3">
                                <form>

                                    <div className="row">
                                        <div className="col">
                                            <label>
                                                Vorname
                                                {this.state.firstNameIncomplete ?
                                                    <div className={"Incomplete-Warning"}>Bitte trage deinen Vornamen
                                                        ein.</div> : null}
                                                <input type="text" name="firstName" ref={this.refFirstName}/>
                                            </label>
                                        </div>
                                        <div className="col">
                                            <label>
                                                Name
                                                {this.state.nameIncomplete ?
                                                    <div className={"Incomplete-Warning"}>Bitte trage deinen Namen
                                                        ein.</div> : null}
                                                <input type="text" name="name" ref={this.refName}/>
                                            </label>
                                        </div>
                                    </div>

                                    <label>
                                        Firma
                                        <span className={"optional"}>(optional)</span>
                                        <input type="text" name="firma" ref={this.refFirma}/>
                                    </label>

                                    <label>
                                        Strasse, Hausnummer
                                        {this.state.addressIncomplete ?
                                            <div className={"Incomplete-Warning"}>Bitte trage deine Adresse
                                                ein.</div> : null}
                                        <input type="text" name="address" ref={this.refAddress}/>
                                    </label>

                                    <label>
                                        Postleitzahl, Ort
                                        {this.state.cityIncomplete ?
                                            <div className={"Incomplete-Warning"}>Bitte trage deinen Wohnort
                                                ein.</div> : null}
                                        <input type="text" name="city" ref={this.refCity}/>
                                    </label>

                                    <label>
                                        E-Mail-Adresse
                                        {this.state.emailIncomplete ?
                                            <div className={"Incomplete-Warning"}>Bitte trage deine Email
                                                ein.</div> : null}
                                        {this.state.invalidEmail ?
                                            <div className={"Incomplete-Warning"}>Bitte trage eine gültige Email
                                                ein.</div> : null}
                                        <input type="text" name="eMail" ref={this.refEmail}/>
                                    </label>

                                    <label>
                                        Telefonnummer
                                        <span className={"optional"}>(optional)</span>
                                        <input type="text" name="telefonnummer" ref={this.refTel}/>
                                    </label>

                                    <label>
                                        Anmerkungen
                                        <span className={"optional"}>(optional)</span>
                                        <div>
                                            <textarea name="message" rows="4" className="form-control"
                                                      ref={this.refMessage}/>
                                        </div>
                                    </label>

                                </form>

                                <div className="small mb-3">
                                    Mit dieser Bestellung akzeptieren Sie unsere <Link to="/agb" className="inline-link">Allgemeinen Geschäftsbedingungen</Link> und<Link to="/datenschutz" className="inline-link">Datenschutzerklärung</Link>. Wenn Sie unseren Newsletter abonnieren möchten, klicken Sie <a href={window.Craft.newsletterAnmelungUrl} target="_blank"  className="inline-link">hier</a>.

                                </div>

                                {this.state.somethingWrong ?
                                    <div className={"Incomplete-Warning"}>Oh nein! Leider konnten wir deine Anfrage
                                        nicht
                                        entgegen nehmen. Bitte versuche es erneut oder prüfe deine
                                        Internetverbindung.</div> : null}
                                <button className={"btn btn-dark aktiv-regular"} onClick={this.submitData}>
                                    {this.state.sending
                                        ? 'Bitte Warten'
                                        : 'Submit'
                                    }
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <BestellungSuccess/>
            )
        }
    }
}

export default BestellungsanfrageView
