import React, {useState} from "react";
import {ButtonLike} from "../Icons/Icons";

export const WeinImage = props => {

    const [loaded,setLoaded] = useState(false);

    return (
        <div className={`WeinImage ${ loaded ? "loaded" : "loading" }`}>
            {
                props.bild.length > 0 &&
                props.bild.map( (img,index) =>
                    <img
                        key={props.id+"-"+index}
                        alt={img.title}
                        src={img.url}
                        width={img.width}
                        height={img.height}
                        onLoad={()=>setLoaded(true)}
                    />
                )
            }
            <div className="WeinImage-Like">
                <ButtonLike id={props.id} />
            </div>

            <div className="WeinTags aktiv-regular">
                { props.weinTags.map( (tag,index) => <div key={tag+"-"+index} className="WeinTag">{tag.title}</div>) }
            </div>

        </div>
    )
};
