import React from "react";
import {NavLink} from "react-router-dom";
import {IconHeart, IconBag} from "../Icons/Icons";

export const DesktopNav = props => {
    return (
        <div className="PageHeader-Desktop">
            <nav className={"Navigation-Main headline-3"}>
                <ul>
                    <li><NavLink to={"/"}>Home</NavLink></li>
                    <li><NavLink to={"/sortiment"}>Sortiment</NavLink></li>
                    <li><NavLink to={"/weinland"}>Weinland</NavLink></li>
                    <li><NavLink to={"/ueberuns"}>Über uns</NavLink></li>
                </ul>
            </nav>
            <nav className="Navigation-Sub aktiv-regular">
                <ul className={"Navigation-Main headline-3"}>
                    <li><NavLink to={"/favoriten"}><IconHeart/><span
                        className={"smallCounter aktiv-regular"}>{props.favorites.length}</span></NavLink>
                    </li>
                    <li><NavLink to={"/warenkorb"}><IconBag/><span
                        className={"smallCounter aktiv-regular"}>{props.cart.length}</span></NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
