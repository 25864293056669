import React, {useContext} from "react"
import {GlobalContext} from "../../containers/GlobalProvider";


export const Lieferkosten = () => {

    const value = useContext(GlobalContext);

    return (
        <div className="Lieferkosten container">
            <div className="row">
                {value?.globalSet.lieferkosten.map((option, i) =>
                    <div
                        key={option.id}
                        className={
                            "Lieferkosten-Option col-12 col-md-6 col-lg-4 text-center aktiv-regular"
                            + (i === 0 ? " offset-lg-2 " : "")}
                        dangerouslySetInnerHTML={{__html: option.text}}
                    />
                )}
            </div>
        </div>
    )

}