import React, {useEffect, useState} from 'react';
import Routing from './Routing';
import PageHeader from "./components/Navigation/PageHeader";
import {BrowserRouter as Router, matchPath, Route} from "react-router-dom";
import PageFooter from "./components/PageFooter/PageFooter";
import {DataProvider} from './components/DataProvider'
import {CookiesProvider} from "react-cookie";
import FavoritesProvider from "./components/Providers/FavoritesProvider";
import CartProvider from "./components/Providers/CartProvider";
import {GlobalProvider} from "./containers/GlobalProvider";

function App() {
    return (
        <Router>
            <div className="App">
                <CookiesProvider>
                    <DataProvider>
                        <FavoritesProvider>
                            <CartProvider>
                                <GlobalProvider>
                                    <Route component={ScrollManager}/>
                                    <PageHeader/>
                                    <Routing/>
                                    <PageFooter/>
                                </GlobalProvider>
                            </CartProvider>
                        </FavoritesProvider>
                    </DataProvider>
                </CookiesProvider>
            </div>
        </Router>
    );
}

export default App;


const ScrollManager = props => {
    useEffect(() => {
            if (matchPath(props.location.pathname, {path: "/weinland/:category/:id"})) {
            } else {
                window.scrollTo(0, 0);
            }
        },
        [props.location.key]
    );
    return null;
}