import React, {Component} from 'react';
import {Link} from "react-router-dom";

const Intro = props => {
    return (
        <div className="Intro">
            <div className="IntroImageSection">
                {
                    props.fullscreenVideo.length > 0 ?
                        props.fullscreenVideo.map(video =>
                            <video
                                key={video.url}
                                className="FullscreenVideo"
                                width={video.width}
                                height={video.height}
                                poster={ props.introBild[0].url }
                                autoPlay
                                muted
                                loop
                                playsInline
                            >
                                <source src={video.url} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        )
                        :
                        props.introBild.map(img =>
                            <img
                                key={img.id}
                                src={img.url}
                                alt={img.title}
                                className="IntroBild"
                                width={img.width}
                                height={img.height}
                            />
                        )
                }

                <div className="IntroHeadline headline-1">
                    <div className="IntroLogo">
                        <img
                            src={window.Craft.logo.url}
                            alt="cava hispania"
                            width={window.Craft.logo.width}
                            height={window.Craft.logo.height}
                        />
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Intro;