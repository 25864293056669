import React, {Component} from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BestellungsanfrageView from "../components/BestellungsanfrageView/BestellungsanfrageView";

class Bestellungsanfrage extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {
        cart: [],
        gastro: false
    }

    constructor(props) {
        super(props);
        const {cookies} = props
        this.state.cart = cookies.get('cart') || []
        this.state.gastro = cookies.get('gastro') || false
    }

    componentDidMount() {
        // Check the total Amount
        let totalAmount = 0
        this.state.cart.map( entry => totalAmount = totalAmount + entry.amount )
        this.setState({ totalAmount })
    }

    render() {
        if (this.state.totalAmount > 0) {
            return (
                <BestellungsanfrageView {...this.props} cart={this.state.cart} gastro={Number(this.state.gastro) === 1} />
            )
        } else {
            return (
                <div>Sorry aber du hast wohl keine Menge bei deiner Auswahl angegeben. Schau doch nochmal in deinen Warenkorb.</div>
            )
        }
    }
}

export default withCookies(Bestellungsanfrage)
