import React from 'react';
import {NavLink} from "react-router-dom";
import {WeinImage} from "../Wein/WeinImage";
import {WeinMeta} from "../Wein/WeinMeta";

const SortimentCard = props => {
    return (
        <div className={"SortimentCard col-xs-12 col-6 col-sm-6 col-lg-4"}>
            <NavLink to={`/${props.uri}`} className="SortimentCard-Link">

                <div className="SortimentCard-Image">
                    <WeinImage {...props} />
                </div>

                <div className="SortimentCard-Body">
                    <h3>{props.title}</h3>
                    <WeinMeta {...props} />
                </div>

            </NavLink>
        </div>
    );
};

export default SortimentCard;
