import React, {useEffect} from 'react';
import {ReactComponent as KarteSVG} from './Karte.svg';
import {useHistory} from "react-router-dom";


export const Karte = props => {

    let history = useHistory();

    useEffect(() => {


            for (let item of document.getElementsByClassName('weinladndsvgRegion')) {
                item.classList.remove("region-active");
            }
            if (props.match) {
                const activeItem = document.getElementById(`ID-${props.match.params.id.split("-")[0]}`);
                if (activeItem) {
                    document.getElementById(`ID-${props.match.params.id.split("-")[0]}`).classList.add("region-active");
                }
            }
        },
        [props.match]
    );


    function handleClick(event) {
        event.persist();
        if (event.target.classList.contains("weinlandsvgRegionShape")) {
            const regionID = event.target.parentNode.id.split("-")[1];
            history.push(`/weinland/region/${regionID}`);
        }
    }

    return <KarteSVG onClick={handleClick}/>;

}
