import React, {useState} from "react";

import {
    IconBodega,
    IconRegion,
    IconSortierung,
    IconWeinsorte,
    IconFilter,
    IconArrowUp,
    IconCheck,
    IconDownload, IconTrash,
} from "../../Icons/Icons";

import {CSSTransition} from "react-transition-group";
import {CartContext} from "../../Providers/cart-context";


const dropdownOptions = {
    closed: "CLOSED",
    sortiertung: "SORTIERUNG",
    weinsorte: "WEINSORTE",
    bodega: "BODEGA",
    region: "REGION",
};

export const FilterInterface = props => {

    // console.log(props)
    const [showMobileFilter, setShowMobileFilter] = useState(false);
    const [showMobileDownload, setShowMobileDownload] = useState(false);
    const [dropdown, setDropdown] = useState(dropdownOptions.closed);

    const handleMobileFilter = (_bool) => {
        setDropdown(dropdownOptions.closed);
        setShowMobileFilter(_bool)
    };

    const handleDropdown = (_fromDropdown) => {
        if (_fromDropdown === dropdown) {
            setDropdown(dropdownOptions.closed);
        } else {
            setDropdown(_fromDropdown);
        }
    };


    return (
        <CartContext.Consumer>
            {
                ({gastro}) => (
                    <div className="FilterInterface">

                        <div className="FilterInterface-MobileHeader">
                            <div className="container">

                                <button
                                    className={"InterfaceOpenButton button-reset interface-button aktiv-regular mb-3"}
                                    onClick={() => handleMobileFilter(!showMobileFilter)}>
                                    <IconFilter block/> Filter
                                </button>

                                {
                                    !gastro ?
                                        <a
                                            href="/preislisten/preisliste-privat"
                                            className={"InterfaceOpenButton button-reset interface-button aktiv-regular"}
                                        >
                                            <IconDownload block/> Preisliste
                                        </a>
                                        :
                                        <a
                                            href="/preislisten/preisliste-gastro"
                                            className={"InterfaceOpenButton button-reset interface-button aktiv-regular"}
                                        >
                                            <IconDownload block/> Gastronomie Preislist
                                        </a>
                                }

                            </div>
                        </div>


                        <div className={`FilterInterface-Body ${showMobileFilter ? "open" : ""}`}>
                            <div className="container">

                                <div className="mb-3">
                                    <button
                                        className={"FilterInterface-BodyClose-Btn button-reset interface-button aktiv-regular"}
                                        onClick={() => handleMobileFilter(!showMobileFilter)}>
                                        <IconArrowUp block/>
                                    </button>
                                </div>


                                <div className="row">


                                    <div className="col-12 col-lg-3">
                                        <div
                                            className={`
                                Filter-Container 
                                ${dropdown === dropdownOptions.weinsorte ? "open" : ""}
                                ${props.checkFilter("weinsorte") ? "active" : ""}
                                `}
                                        >

                                            <div
                                                className="Filter-Head"
                                                onClick={() => {
                                                    handleDropdown(dropdownOptions.weinsorte);
                                                }}>
                                                <button
                                                    className="Filter-HeadButton button-reset interface-button aktiv-regular">
                                                    <IconWeinsorte block={true}/> Kategorie
                                                </button>
                                            </div>

                                            <CSSTransition
                                                in={dropdown === dropdownOptions.weinsorte}
                                                classNames="fade"
                                                timeout={300}
                                                mountOnEnter
                                                unmountOnExit
                                            >
                                                <div className="Filter-Body">
                                                    {props.weinsorte.map(
                                                        option =>
                                                            <div
                                                                key={option.id}
                                                                className="Filter-BodyOption"
                                                            >
                                                                <button
                                                                    onClick={() => props.handleFilterOption("weinsorte", option.id)}
                                                                    className={`
                                                        ${props.checkFilterOption("weinsorte", option.id) ? "active" : ""}
                                                        button-reset interface-button aktiv-regular
                                                    `}>
                                                                    <div className="Filter-OptionCheckbox"><IconCheck
                                                                        block/></div>
                                                                    {option.title}
                                                                </button>
                                                            </div>
                                                    )}
                                                </div>
                                            </CSSTransition>

                                        </div>
                                    </div>


                                    <div className="col-12 col-lg-3">
                                        <div
                                            className={`
                                            Filter-Container 
                                            ${dropdown === dropdownOptions.region ? "open" : ""}
                                            ${props.checkFilter("region") ? "active" : ""}
                                            `}
                                        >

                                            <div
                                                className="Filter-Head"
                                                onClick={() => {
                                                    handleDropdown(dropdownOptions.region);
                                                }}
                                            >
                                                <button
                                                    className="Filter-HeadButton button-reset interface-button aktiv-regular">
                                                    <IconRegion block={true}/> Region
                                                </button>
                                            </div>

                                            <CSSTransition
                                                in={dropdown === dropdownOptions.region}
                                                classNames="fade"
                                                timeout={300}
                                                mountOnEnter
                                                unmountOnExit
                                            >
                                                <div className="Filter-Body">
                                                    {props.region.map(
                                                        option =>
                                                            <div
                                                                key={option.id}
                                                                className="Filter-BodyOption"
                                                            >
                                                                <button
                                                                    onClick={() => props.handleFilterOption("region", option.id)}
                                                                    className={`
                                                                        ${props.checkFilterOption("region", option.id) ? "active" : ""}
                                                                        button-reset interface-button aktiv-regular
                                                                    `}>
                                                                    <div className="Filter-OptionCheckbox">
                                                                        <IconCheck block/>
                                                                    </div>
                                                                    {option.title}
                                                                </button>
                                                            </div>
                                                    )}
                                                </div>
                                            </CSSTransition>

                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-3">
                                        <div
                                            className={`
                                Filter-Container 
                                ${dropdown === dropdownOptions.bodega ? "open" : ""}
                                ${props.checkFilter("bodega") ? "active" : ""}
                                `}
                                        >

                                            <div
                                                className="Filter-Head"
                                                onClick={() => {
                                                    handleDropdown(dropdownOptions.bodega);
                                                }}>
                                                <button
                                                    className="Filter-HeadButton button-reset interface-button aktiv-regular">
                                                    <IconBodega block={true}/> Bodega
                                                </button>
                                            </div>

                                            <CSSTransition
                                                in={dropdown === dropdownOptions.bodega}
                                                classNames="fade"
                                                timeout={300}
                                                mountOnEnter
                                                unmountOnExit
                                            >
                                                <div className="Filter-Body">
                                                    {props.bodega.map(
                                                        option =>
                                                            <div
                                                                key={option.id}
                                                                className="Filter-BodyOption"
                                                            >
                                                                <button
                                                                    onClick={() => props.handleFilterOption("bodega", option.id)}
                                                                    className={`
                                                        ${props.checkFilterOption("bodega", option.id) ? "active" : ""}
                                                        button-reset interface-button aktiv-regular
                                                    `}>
                                                                    <div className="Filter-OptionCheckbox"><IconCheck
                                                                        block/></div>
                                                                    {option.title}
                                                                </button>
                                                            </div>
                                                    )}
                                                </div>
                                            </CSSTransition>

                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-3">
                                        <div
                                            className={`
                                Filter-Container 
                                ${dropdown === dropdownOptions.sortierung ? "open" : ""}
                                ${props.checkFilter("sorting") ? "active" : ""}
                                `}>
                                            <div
                                                className="Filter-Head"
                                                onClick={() => {
                                                    handleDropdown(dropdownOptions.sortierung);
                                                }}
                                            >
                                                <button
                                                    className="Filter-HeadButton button-reset interface-button aktiv-regular">
                                                    <IconSortierung block={true}/> Sortierung
                                                </button>
                                            </div>
                                            <CSSTransition
                                                in={dropdown === dropdownOptions.sortierung}
                                                classNames="fade"
                                                timeout={300}
                                                mountOnEnter
                                                unmountOnExit
                                            >
                                                <div className="Filter-Body">
                                                    {props.sorting.map(
                                                        option =>
                                                            <div
                                                                key={option.handle}
                                                                className="Filter-BodyOption"
                                                            >
                                                                <button
                                                                    onClick={() => props.handleSortOption(option.handle)}
                                                                    className={`
                                                        ${props.checkSortOption(option.handle) ? "active" : ""}
                                                        button-reset interface-button aktiv-regular
                                                    `}>
                                                                    <div className="Filter-OptionCheckbox"><IconCheck
                                                                        block/></div>
                                                                    {option.title}
                                                                </button>
                                                            </div>
                                                    )}
                                                </div>
                                            </CSSTransition>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div className="container d-flex justify-content-between flex-nowrap">
                            <div className="">
                                {
                                    props.checkFilter() &&
                                    <button
                                        onClick={props.clearFilters}
                                        className="FilterInterface-OptionHeadButton button-reset interface-button aktiv-regular"
                                    >
                                        <IconTrash block={true}/> Filter zurücksetzen</button>
                                }
                            </div>

                            <div className="d-none d-lg-block">
                                {
                                    !gastro ?
                                        <a
                                            href="/preislisten/preisliste-privat"
                                            className={"InterfaceOpenButton button-reset interface-button aktiv-regular"}
                                        >
                                            <IconDownload block/> Preisliste
                                        </a>
                                        :
                                        <a
                                            href="/preislisten/preisliste-gastro"
                                            className={"InterfaceOpenButton button-reset interface-button aktiv-regular"}
                                        >
                                            <IconDownload block/> Gastronomie Preisliste
                                        </a>
                                }
                            </div>
                        </div>

                        <div className="container d-none d-lg-block">
                            <div className="FilterInterfaceFooter"/>
                        </div>

                    </div>
                )
            }
        </CartContext.Consumer>
    )
};