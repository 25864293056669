import React, {Component} from 'react';

const Aktuelles = props => {

    return (
        <div className="Aktuelles container">
            <div className="AktuellesContent">

                <h1 className="AktuellesSectionHeadline banner-1 text-center">Aktuelles</h1>

                <div className="AktuellesEntryContainer row">

                    <div className="col-12 col-md-10 offset-md-1 col-lg-5 offset-lg-1">
                        {props.entries.map((entry,index) =>
                            {
                                if (index % 2 !== 0) {
                                    return <AktuellesEntry key={entry.id} {...entry} />
                                } else {
                                    return null;
                                }}
                        )}
                    </div>
                    <div className="col-12 col-md-10 offset-md-1 col-lg-5 offset-lg-0">
                        {props.entries.map((entry,index) =>
                            {
                                if (index % 2 == 0) {
                                    return <AktuellesEntry key={entry.id} {...entry} />
                                } else {
                                    return null;
                                }}
                        )}
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Aktuelles;


const AktuellesEntry = props => (
    <div className="AktuellesEntry">
        <div className="AktuellesEntryContent">

            <div className="AktuellesEntry-Date aktiv-regular">
                {props.dateCreated}
            </div>

            {
                props.aktuellesBild &&
                props.aktuellesBild.map(bild =>
                    <img
                        key={bild.url}
                        src={bild.url}
                        alt={bild.title}
                        className="AktuellesEntry-Bild"/>)
            }

            <h2 className="AktuellesEntry-Headline">{props.title}</h2>

            <div className="AktuellesEntry-Body fliesstext-klein redactor-body"
                 dangerouslySetInnerHTML={{__html: props.aktuellesBody}}/>

            {
                props.aktuellesLink !== null &&
                props.aktuellesLink.map(link =>
                    <a
                        key={link.url}
                        href={link.url}
                        className="AktuellesEntry-Link button-reset aktiv-btn-regular">
                        {link.linkbeschreibung}
                    </a>
                )
            }

        </div>
    </div>
);