import React from "react";
import {FavoritesContext} from "../Providers/favorites-context";
import {CartContext} from "../Providers/cart-context";
import {IconTrash} from "../Icons/Icons";

export const FavoritenGeneralAction = props => {
    return (
        <FavoritesContext.Consumer>
            { ({favorites, removeFavorites}) =>
                <CartContext.Consumer>
                    {({addFavoritesToCart}) =>
                        <div className={"FavoritenGeneralAction container"}>
                            <button
                                className={"btn btn-link aktiv-btn-regular"}
                                onClick={removeFavorites}>
                                <IconTrash />
                                Alle Favoriten löschen
                            </button>
                            <button
                                className={"btn btn-dark aktiv-btn-regular"}
                                onClick={(event) => {
                                event.preventDefault();
                                addFavoritesToCart(favorites);}}>
                                Alle Favoriten in den Warenkorb
                            </button>
                        </div>
                    }
                </CartContext.Consumer>
            }
        </FavoritesContext.Consumer>
    )
}
