import React from 'react';
import {WarenkorbView} from "../components/WarenkorbView/WarenkorbView";
import {CartContext} from "../components/Providers/cart-context";

export const Warenkorb = props => {
    return (
        <CartContext.Consumer>
            { ({cart}) => {
                return (
                    <div>
                        <WarenkorbView {...props} cart={cart}/>
                    </div>
                )
            }}
        </CartContext.Consumer>
    )
}
