import React, {useEffect} from "react";
import {Link} from 'react-router-dom';

export const WeinlandSearchresult = props => {

    useEffect(() => {
        if (props.viewSearchResults) {
            document.getElementById("WeinlandSearchResult").scrollTop = 0;
        }
    }, [props.viewSearchResults])

    return (
        <div id="WeinlandSearchResult"
             className={`WeinlandSearchResult ${props.viewSearchResults ? "open" : "closed"}`}>
            <div className={`container`}>


                <div className={"Select row"}>


                    <div className={"Select-Region col-12 col-md-6 col-lg-4 offset-lg-2"}>
                        <h1 className={"Select-Header aktiv-bold"}>
                            Region
                        </h1>
                        {
                            props.regionsRenderList.length ?
                                props.regionsRenderList.map(item =>
                                    <Link to={`/${item.uri}`} key={item.id}>
                                        <div className="Select-Item aktiv-regular">
                                            {item.title}
                                        </div>
                                    </Link>
                                )
                                :
                                <div className={"Select-Item noSearchResult aktiv-regular"}>
                                    Keine Region gefunden
                                </div>
                        }
                    </div>


                    <div className={"Select-Bodega col-12 col-md-6 col-lg-4 "}>
                        <h1 className={"Select-Header aktiv-bold"}>
                            Bodega
                        </h1>
                        {
                            props.bodegasRenderList.length ?
                                props.bodegasRenderList.map(item =>
                                    <Link to={`/${item.uri}`} key={item.id}>
                                        <div className="Select-Item aktiv-regular">
                                            {item.title}
                                        </div>
                                    </Link>
                                )
                                :
                                <div className={"Select-Item noSearchResult aktiv-regular"}>
                                    Keine Region gefunden
                                </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
};