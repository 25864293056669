import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Loader} from "../components/Icons/Loader";
import {CSSTransition} from "react-transition-group";
import {SimpleLoadIndicator} from "../components/SimpleLoadIndicator/SimpleLoadIndicator";

const GET_GREETING = gql`
    query ( $section:[String] ) {
        entries (section: $section, limit: 1 ) {
            title
            id
            ... on agb_agb_Entry{
                body
            }
            ... on impressum_impressum_Entry{
                body
            }
            ... on datenverarbeitung_datenverarbeitung_Entry{
                body
            }
            ... on datenschutz_datenschutz_Entry{
                body
            }
        }
    }

`;

export const Single = props => {

    const {section} = props.match.params;

    const {loading, error, data} = useQuery(GET_GREETING, {
        variables: {section: section},
    });

    return (
        <div className="SinglePage container">

            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SimpleLoadIndicator />
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SimpleLoadIndicator error />
            </CSSTransition>

            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                >
                <div>
                    {
                        data ?
                        data.entries.map(entry =>
                        <div key={entry.id}>
                            <h1>{entry.title}</h1>
                            <div dangerouslySetInnerHTML={{__html: entry.body}} className="redactor-body fliesstext-klein mt-5" />
                        </div>
                    ):null
                    }
                </div>
            </CSSTransition>

        </div>
    );
};

