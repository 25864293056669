import React from "react";
import {Link} from "react-router-dom";

export const SocialMedia = props => {
    return(
        <div className="SocialMedia">
            <div className="SocialMediaImageSection">
                {props.socialMediaBild.map(img =>
                    <img key={img.id} src={img.url} alt={img.title} className="SocialMediaBild"/>
                )}
                <div className="SocialMediaButtons">
                    <div className="">
                        {window.Craft.socialMedia.map( media =>
                            <a key={media.url} href={media.url} className="button-reset button-standard button-light aktiv-btn-regular m-3">{media.name}</a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}