import React from "react";
import {ReactComponent as SvgHeart} from "../../static/Icons-Svg/Icon-Heart.svg";
import {ReactComponent as SvgBodega} from "../../static/Icons-Svg/Icon-Bodega.svg";
import {ReactComponent as SvgRegion} from "../../static/Icons-Svg/Icon-Region.svg";
import {ReactComponent as SvgWeinsorte} from "../../static/Icons-Svg/Icon-Weinsorte.svg";
import {ReactComponent as SvgSortierung} from "../../static/Icons-Svg/Icon-Sortierung.svg";
import {ReactComponent as SvgTrauben} from "../../static/Icons-Svg/Icon-Trauben.svg";
import {ReactComponent as SvgBag} from "../../static/Icons-Svg/Icon-Bag.svg";
import {ReactComponent as SvgDownload} from "../../static/Icons-Svg/Icon-Download.svg";
import {ReactComponent as SvgMinus} from "../../static/Icons-Svg/Icon-Minus.svg";
import {ReactComponent as SvgSchloss} from "../../static/Icons-Svg/Icon-Schloss.svg";
import {ReactComponent as SvgArrowUp} from "../../static/Icons-Svg/Icon-Arrow-Up.svg";
import {ReactComponent as SvgArrowRight} from "../../static/Icons-Svg/Icon-Arrow-Right.svg";
import {ReactComponent as SvgArrowLeft} from "../../static/Icons-Svg/Icon-Arrow-Left.svg";
import {ReactComponent as SvgCheck} from "../../static/Icons-Svg/Icon-Check.svg";
import {ReactComponent as SvgTrash} from "../../static/Icons-Svg/Icon-Trash.svg";
import {ReactComponent as SvgFilter} from "../../static/Icons-Svg/Icon-Filter.svg";

// import FavoritesProvider from "../Providers/FavoritesProvider";
import {FavoritesContext} from "../Providers/favorites-context";

// TODO: move this component to a better place (paradise)
export const ButtonLike = props => {
    const {id} = props;
    return (
        <FavoritesContext.Consumer>
            { ({checkFavorite,handleFavorite}) =>
                <div className={`LikeButton ${ checkFavorite(id) ? "liked" : ""}`}>
                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            handleFavorite(id);
                        }}
                        className={`button-reset button-transparent`}>
                        <SvgHeart/>
                    </button>
                </div>
            }
        </FavoritesContext.Consumer>
    )
};


export const IconBodega = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgBodega/></div>)
};
export const IconWeinsorte = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgWeinsorte/></div>)
};
export const IconRegion = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgRegion/></div>)
};
export const IconSortierung = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgSortierung/></div>)
};
export const IconTrauben = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgTrauben/></div>)
};
export const IconHeart = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgHeart/></div>)
};
export const IconBag = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgBag/></div>)
};
export const IconDownload = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgDownload/></div>)
};
export const IconSchloss = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgSchloss/></div>)
};
export const IconMinus = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgMinus/></div>)
};
export const IconArrowUp = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgArrowUp/></div>)
};
export const IconArrowRight = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgArrowRight/></div>)
};
export const IconArrowLeft = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgArrowLeft/></div>)
};
export const IconCheck = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgCheck/></div>)
};
export const IconTrash = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgTrash/></div>)
};
export const IconFilter = props => {
    return (<div className={props.block ? "Icon-Block" : "Icon-Inline"}><SvgFilter/></div>)
};