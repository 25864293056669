import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Aktuelles from "../components/Home/Aktuelles";
import Intro from "../components/Home/Intro";
import {SocialMedia} from "../components/Home/SocialMedia";
import {Loader} from "../components/Icons/Loader";
import {CSSTransition} from "react-transition-group";

const DATA_QUERY = gql`
    query {
        aktuelles: entries (section: "aktuelles", orderBy: "lft" ) {
            title
            id
            uri
            dateCreated @formatDateTime (format: "d. M. Y")
            ... on aktuelles_aktuelles_Entry{
                aktuellesBody
                aktuellesBild{
                    url
                    title
                    id
                }
                aktuellesLink {
                    linkbeschreibung
                    url
                }
            }
        }
        intro: entry(section:"home"){
            id
            ... on home_home_Entry{
                introBild{
                    url
                    title
                    id
                }
                fullscreenVideo{
                    url
                    width
                    height
                    title
                }
                socialMediaBild{
                    url
                    title
                    id
                }
            }
        }
    }
`;


export const Home = props => {

    const {loading, error, data} = useQuery(DATA_QUERY, {variables: {}});

    return (
        <div>

            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <Loader/>
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <p>Error </p>
            </CSSTransition>

            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                    {data &&
                        <div>
                            <Intro {...data.intro} />
                            <Aktuelles entries={data.aktuelles}/>
                            <SocialMedia {...data.intro} />
                        </div>
                    }
            </CSSTransition>

        </div>
    );
};