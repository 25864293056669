import React from 'react';
import {CartContext} from "../Providers/cart-context";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {transformPrice} from "../../utils/transform-price";

function getTotal (prices, cart) {
    let total = 0
    cart.map(entry => {
      prices.map(price => {
            if (price.vinoId === entry.vinoId) {
                total = total + Number(price.price * entry.amount)
            }else{
                return null;
            }
        });
      return null;
    });
    return total
}


const QUERY = gql`
    query ( $id:[QueryArgument]) {
        entries ( id: $id, ) {
            id
            ... on sortiment_sortiment_Entry{
                preisPrivat
                preisGastro

            }
        }
    }
`;

export const WarenkorbTotal = props => {

    let vinoIds = []
    // Checking if from cart or favorites
    props.cart.map(entry => vinoIds.push(entry.vinoId))

    // Pulling Data by current Cookies
    const {loading, error, data} = useQuery(QUERY, {
        // GraphQL Accepts Array
        variables: {id: vinoIds},
    });

    if (loading) return <p>Loading ...</p>;
    if (error) return <p>Error </p>;

    // getting the vinoPrices
    let vinoPrices = []

    return (
        <CartContext.Consumer>
            { ({cart, gastro}) => {
                // Set Prices
                if (data) {
                    data.entries.map(entry => { vinoPrices.push({vinoId: entry.id, price: gastro ? entry.preisGastro : entry.preisPrivat })})
                }
                // Getting the total Amount
                let total = getTotal(vinoPrices, cart)
                return (
                    <div className="WarenkorbTotal">
                        <div className={"WarenkorbTotal-Total row headline-3"}>
                            <span className={"col"}>Total { gastro ? <span className={"aktiv-regular"}>exkl. MwSt.</span> : <span className={"aktiv-regular"}>inkl. MwSt.</span> }</span>
                            <span className={"col text-right"}>CHF {transformPrice(total)}</span>
                        </div>
                    </div>
                )
            }}
        </CartContext.Consumer>
    )
};
