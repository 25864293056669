import React, {useContext, useState} from "react"
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";



const QUERY = gql`
    query{
        globalSet(id:227){
            title
            ...on  bestellanfrage_GlobalSet{
                lieferkosten{
                    ...on lieferkosten_option_BlockType{
                        text
                        id
                    }
                }
            }
        }
    }
`;



export const GlobalContext = React.createContext(null);



export const GlobalProvider = ({children}) => {

    const {data} = useQuery(QUERY);


    return(
        <GlobalContext.Provider value={data}>
            {children}
        </GlobalContext.Provider>
    )
}

