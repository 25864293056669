import React from "react";

export const CartContext = React.createContext({
    cart: [],
    gastro: false,
    addToCart: () => {},
    checkCart: () => {},
    removeFromCart: () => {},
    clearCart: () => {},
    getVinoFromCart: () => {},
    setVinoAmount: () => {},
    addFavoritesToCart: () => {},
    setGastroBool: () => {}
});
