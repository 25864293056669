import React from "react";
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {IconRegion} from "../../Icons/Icons";
import {Link} from 'react-router-dom';
import {SimpleLoadIndicator} from "../../SimpleLoadIndicator/SimpleLoadIndicator";


const DATA_QUERY = gql`
    query ( $id:[QueryArgument], $related:[QueryArgument] ) {
        category ( id: $id ) {
            title
            id
            uri
            ... on region_Category{
                regionText
                regionFakten {
                    bezeichnung
                    info
                }
            }
        }
        relatedBodegas: categories(relatedTo:$related){
            title
            uri
        }
    }
`;


export const RegionPortrait = props => {


    const catId = props.match.params.slug.split("-")[0];

    const {loading, error, data} = useQuery(
        DATA_QUERY,
        {
            variables: {
                id: catId,
                related: catId,
            },

        }
    );


    if (loading) return <SimpleLoadIndicator/>;
    if (error) return <SimpleLoadIndicator error />;

    // Check if data is returned
    if (data.category) {
        return (
            <div className="Portrait container">


                <div className={"SubHeader"}>
                    <div className="SubHeader-Icon">
                        <IconRegion block/>
                    </div>
                    <h1>
                        {data.category.title}
                    </h1>
                </div>


                {/* FAKTEN & TEXT */}
                <div className={"SubContent row"}>

                    {
                        // Check if there are any Facts
                        data.category.regionFakten &&

                        // If some facts are defined return them
                        <div className={"SubFacts col-12 col-md-4 offset-lg-1"}>
                            {
                                // Map facts
                                data.category.regionFakten.map(fact =>
                                    <div key={fact.bezeichnung} className={"FaktenZeile"}>
                                        <div className={"FaktenHead aktiv-bold"}>{fact.bezeichnung}</div>
                                        <div className={"FaktenInfo aktiv-regular"}>{fact.info}</div>
                                    </div>
                                )
                            }
                        </div>
                    }


                    {
                        // Region Text
                        data.category.regionText &&
                        <div
                            className={"SubText col-12 col-md-8 col-lg-6 fliesstext-gross"}
                            dangerouslySetInnerHTML={{__html: data.category.regionText}}
                        />
                    }

                </div>


                {/* LINNKS TO ALL BODEGAS   */}
                {data.relatedBodegas.length > 0 &&
                <div className={"bodegaLinks"}>

                    <h3>Alle Bodegas der Region</h3>

                    {
                        data.relatedBodegas.map((bodega, index) =>
                            <div key={bodega.id + index}>
                                <Link to={`/${bodega.uri}`}>
                                    {bodega.title}
                                </Link>
                            </div>
                        )
                    }
                </div>
                }


            </div>
        );
    }
    else return <div>Wir konnten deine Anfrage leider nicht Verarbeiten.</div>
};
