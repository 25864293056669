import React from 'react';
import {useQuery} from "@apollo/react-hooks";
import gql from 'graphql-tag';
import {WeinView} from "../components/WeinView/WeinView";
import {Loader} from "../components/Icons/Loader";
import {CSSTransition} from "react-transition-group";
import {SimpleLoadIndicator} from "../components/SimpleLoadIndicator/SimpleLoadIndicator";

const GET_GREETING = gql`
    query ( $id:[QueryArgument]) {
        entry ( id: $id, ) {
            title
            slug
            id
            ... on sortiment_sortiment_Entry{
                bodega {
                    title
                    slug
                    uri
                }
                region{
                    title
                    slug
                    uri
                }
                traubensorte{
                    title
                    slug
                    uri
                }
                weinsorte{
                    title
                    slug
                    uri
                }
                bild{
                    url @transform( handle:"sortimentPreview" )
                    title
                }
                jahr
                fuellmenge
                preisPrivat
                preisGastro
                beschreibung
                weinFakten{
                    col1
                    col2
                }
                weinTags{
                    title
                }
            }
        }
    }
`;

export const Wein = props => {
    // Extractinf the vinoURI from URL
    const {vinoUri} = props.match.params;

    // Added Query Variable
    const {loading, error, data} = useQuery(GET_GREETING, {
        variables: {id: vinoUri},
    });

    return (
        <React.Fragment>
            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SimpleLoadIndicator loading={loading} />
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SimpleLoadIndicator error />
            </CSSTransition>

            <CSSTransition
                in={data}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <WeinView {...data} />
            </CSSTransition>
        </React.Fragment>
    );
};
