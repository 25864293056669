import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Ueberuns} from '../components/Ueberuns/Ueberuns';
import {Loader} from "../components/Icons/Loader";
import {CSSTransition} from "react-transition-group";
import {SimpleLoadIndicator} from "../components/SimpleLoadIndicator/SimpleLoadIndicator";


const GQL_QUERY = gql`
    query {
        entry (section: "ueberUns") {
            ... on ueberUns_ueberUns_Entry{
                unsUeberschrift
                unsText
                partner
                unsBilder{
                    url
                    id
                    width
                    height
                }
                ladenBilder{
                    url
                    id
                    width
                    height
                }
                team{
                    ... on team_teammitglied_BlockType{
                        id
                        bild{
                            url
                            width
                            height
                            title
                        }
                        text
                        mitgliedName
                    }
                }
            }
        }
    }
`;


export const UeberunsContainer = props => {

    const {loading, error, data} = useQuery(GQL_QUERY);

    return (


        <div>
            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SimpleLoadIndicator loading={loading} />
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <SimpleLoadIndicator error />
            </CSSTransition>

            <CSSTransition
                in={data}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
            >
                <Ueberuns {...data} />
            </CSSTransition>

        </div>
    );
};