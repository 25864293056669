import React from 'react';

export const SimpleLoadIndicator = props => {
    const {loading,error} = props;
    return (
        <div className={`SimpleLoadIndicator ${loading&&"loading"} ${error&&"error"}`}>
            <div className="SimpleLoadIndicator-Inner">
                {
                    !error ?
                        "Inhalte laden …"
                        :
                        "Die Verbindung zum Server konnte nicht hergestellt werden. Bitte versuchen sie es später erneut."
                }
            </div>
        </div>
    )
}