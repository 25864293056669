import ApolloClient from 'apollo-boost';
import {InMemoryCache} from 'apollo-cache-inmemory';

import {fragmentMatcher} from "./fragmentMatcher";

const cache = new InMemoryCache({fragmentMatcher});

export const apiClient = new ApolloClient({
    cache,
    uri:"https://cavahispania.ch/api",
});