import React, {Component} from 'react';
import {FavoritesContext} from "./favorites-context";
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from 'react-cookie';

class FavoritesProvider extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);
        const { cookies } = props

        this.state = {
            favorites: cookies.get('favorites') || [],
            handleFavorite: this.handleFavorite.bind(this),
            checkFavorite: this.checkFavorite.bind(this),
            removeFavorites: this.removeFavorites.bind(this)
        }
    }

    componentDidUpdate( prevProps, prevState ) {
        if(prevState!==this.state){
            this.mountStateToCookie()
        }
    }

    // mount state to cookie
    mountStateToCookie(){
        const { cookies } = this.props
        const DATE_EXPIRE = new Date(2120, 5, 24, 18, 30)
        cookies.set('favorites', this.state.favorites, DATE_EXPIRE)
    }

    // add or remove favorite to state
    handleFavorite(id){
        let currentFavorites = this.state.favorites
        // console.log(currentFavorites)
        if (currentFavorites.includes(id)) {
            // remove element from cookie
            currentFavorites = currentFavorites.filter(entry => entry !== id)
            this.setState({ favorites: currentFavorites })
        } else {
            // Add cookie
            currentFavorites.push(id)
            this.setState({ favorites: currentFavorites })
        }
    }

    // check if id is in favorits
    checkFavorite(id){
        // return boolean
        return this.state.favorites.includes(id)
    }

    removeFavorites() {
        this.setState({favorites: []})
    }

    render() {
        return (
            <FavoritesContext.Provider value={this.state}>
                {this.props.children}
            </FavoritesContext.Provider>
        );
    }
}

export default withCookies(FavoritesProvider);