import React from 'react';
import { Link } from "react-router-dom";
import {CartContext} from "../Providers/cart-context";
import {IconTrash} from "../Icons/Icons";

export const WarenkorbGenralAction = props => {
    return (
        <CartContext.Consumer>
            {({clearCart}) =>
                <div className="WarenkorbGenralAction">
                    <button onClick={clearCart} className="btn btn-link aktiv-btn-regular"><IconTrash />Warenkorb leeren</button>
                    <Link to="/warenkorb/bestellungsanfrage" className="btn btn-dark aktiv-btn-regular">Zur Bestellung</Link>
                </div>
            }
        </CartContext.Consumer>
    )
}
