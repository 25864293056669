import React, {Component} from 'react';
import {WeinlandIntro} from "./WeinlandIntro";
import {WeinlandSearchresult} from "./WeinlandSearchresult";
import {Route, withRouter} from "react-router-dom";
import {Karte} from "./Karte";
import {RegionPortrait} from "./Portraits/RegionPortrait";
import {BodegaPortrait} from "./Portraits/BodegaPortrait";

class WeinlandView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchStr: "",
            viewSearchResults: false,
            regionsRenderList: this.props.regions,
            bodegasRenderList: this.props.bodegas,
        }
        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.setState({
                searchStr: "",
                viewSearchResults: false,
                regionsRenderList: this.props.regions,
                bodegasRenderList: this.props.bodegas,
            });
        }
    }


    handleInput(e) {
        const inputNode = this.inputRef.current;
        this.setState({
            searchStr: inputNode.value,
            viewSearchResults: true,
            regionsRenderList: filterItems(this.props.regions, inputNode.value),
            bodegasRenderList: filterItems(this.props.bodegas, inputNode.value)
        });
    }

    handleFocus(e) {
        this.setState({
            viewSearchResults: true,
        });
    }

    handleBlur(e) {
        const inputNode = this.inputRef.current;
        this.setState({
            viewSearchResults: !!inputNode.value.length,
        });
    }


    render() {
        return (
            <div className="WeinlandView">
                <div className="Map-Page">


                    <WeinlandIntro text={this.props.weinland.weinlandText}/>


                    <div className="Map-Container">

                        {/* Weinland Karte */}
                        <Route path={"/weinland/region/:id"} children={(route) => <Karte {...route} />}/>

                        {/* Weinland Searchbar */}
                        <div className={"Searchbar container aktiv-btn-regular"}>
                            <input
                                type={"text"}
                                placeholder={"Suche"}
                                className="aktiv-regular"
                                onChange={this.handleInput.bind(this)}
                                onFocus={this.handleFocus.bind(this)}
                                onBlur={this.handleBlur.bind(this)}
                                ref={this.inputRef}
                            />
                        </div>

                    </div>


                    <div className="SearchResult">
                        <WeinlandSearchresult {...this.state} />
                    </div>


                    <div>
                        <Route
                            path="/weinland/region/:slug"
                            component={RegionPortrait}
                        />
                        <Route
                            path="/weinland/bodega/:slug"
                            component={BodegaPortrait}
                        />
                    </div>


                </div>
            </div>
        );
    }
}

export default withRouter(WeinlandView);



const filterItems = (items, searchStr) => {
    let filteredItems = [];
    items.map(
        item => {
            if (item.title.toLowerCase().includes(searchStr)) {
                filteredItems.push(item)
            }
        }
    );
    return filteredItems;
}