import React from 'react'
import Lottie from 'lottie-react-web';
import "./Icon.scss"
import animationData from '../../static/animations/hamburger.json';

export const HamburgerIcon = props => {
    return(
        <Lottie
            direction={props.active ? 1 : -1}
            options={{
                animationData: animationData,
                loop: false
            }}
        />
    )
}
