import React from 'react';
import {IconBag, IconTrash} from "../Icons/Icons";
import np from "nested-property";
import {Link, matchPath} from "react-router-dom";
import {CartContext} from "../Providers/cart-context";
import {transformPrice} from "../../utils/transform-price";

export const ShoppingcartInterface = props => {

    const pageSlug = np.get(matchPath(window.location.pathname, {path: "/:page"}), "params.page");

    return (
        <CartContext.Consumer>
            {({checkCart, removeFromCart, addToCart, getVinoFromCart, setVinoAmount, gastro}) => {
                // If id is already in cart
                let cartInfo = getVinoFromCart(props.id)
                let amount = cartInfo ? Number(cartInfo.amount) : 0

                if (checkCart(props.id)) {
                    return (
                        <div className="ShoppingcartInterface">

                            { pageSlug !== "warenkorb" &&
                                <div className="ShoppingcartInterface-Header aktiv-bold">Im Warenkorb:</div>
                            }

                                <div className="ShoppingcartInterface-Row my-2 d-flex align-items-center justify-content-between">
                                <div>
                                    <button
                                        className="ShoppingcartInterface-AmountBtn btn btn-dark aktiv-regular"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            addToCart(props.id, amount > 1 ? amount - 1 : 1);
                                        }}>
                                        -
                                    </button>
                                    <input
                                        className="ShoppingcartInterface-Amount aktiv-regular"
                                        type="number"
                                        onChange={(e) => setVinoAmount(props.id, e)}
                                        value={amount}
                                    />
                                    <button
                                        className="ShoppingcartInterface-AmountBtn btn btn-dark aktiv-regular"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            addToCart(props.id, (amount + 1));
                                        }}>
                                        +
                                    </button>
                                </div>
                                <div className="aktiv-regular">CHF { gastro ? transformPrice(props.preisGastro * amount) : transformPrice(props.preisPrivat * amount)}</div>
                            </div>


                            <div className="ShoppingcartInterface-Footer">
                            <button
                                className="btn btn-link aktiv-regular p-0 d-inline "
                                onClick={(event) => {
                                    event.preventDefault();
                                    removeFromCart(props.id);
                                }}>
                                <IconTrash/> entfernen
                            </button>

                            {
                                pageSlug !== "favoriten" &&  pageSlug !== "warenkorb" &&
                                <Link
                                    to="/warenkorb"
                                    className="btn btn-dark aktiv-regular"
                                    >
                                    Zum Warenkorb
                                </Link>
                            }
                            </div>

                        </div>
                    )
                } else {
                    return (
                        <div className="ShoppingcartInterface">
                            <button
                                className="aktiv-regular button button-reset"
                                onClick={(event) => {
                                    event.preventDefault();
                                    addToCart(props.id, 1);
                                }}>
                                <IconBag />In den Warenkorb
                            </button>
                        </div>
                    )
                }
            }}
        </CartContext.Consumer>
    )
};
