import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {Single} from './containers/Single';
import {Home} from './containers/Home';
import {Sortiment} from './containers/Sortiment';
import {Wein} from './containers/Wein'
import {Favoriten} from './containers/Favoriten'
import {Warenkorb} from './containers/Warenkorb'
import {UeberunsContainer} from './containers/Ueberuns'

import Bestellungsanfrage from './containers/Bestellungsanfrage'
import {Weinland} from './containers/Weinland'

class Routing extends Component {
    render() {
        return (
            <div className="PageContent">

                <Switch>
                    <Route path={"/"} exact component={Home}/>

                    <Route path={"/sortiment/:vinoUri"} exact component={Wein}/>
                    <Route path={"/sortiment"} component={Sortiment}/>

                    <Route path={"/weinland"} component={Weinland}/>
                    <Route path={"/ueberuns"} component={UeberunsContainer}/>
                    <Route path={"/favoriten"} component={Favoriten}/>
                    <Route path={"/warenkorb/bestellungsanfrage"} exact component={Bestellungsanfrage}/>
                    <Route path={"/warenkorb"} component={Warenkorb}/>

                    <Route path={"/:section(impressum|agb|datenschutz|datenverarbeitung)"} exact component={Single}/>
                    <Redirect to={"/"}/>
                </Switch>
            </div>
        );
    }
}

export default Routing;