import React from 'react';
import {WeinMeta} from "../Wein/WeinMeta";
import {WeinImage} from "../Wein/WeinImage";
import {Link} from "react-router-dom";
import {ShoppingcartInterface} from "../ShoppingcartInterface/ShoppingcartInterface";


export const WeinRow = props => {

    const {entry} = props;
    if (entry) {
        return (
            <div className="WeinRow row">

                <div className="col-3 col-lg-3 ">
                    <Link to={entry.uri}>
                        <WeinImage {...entry} />
                    </Link>
                </div>

                <div className="col-9">

                    <h3>{entry.title}</h3>

                    <WeinMeta {...entry} gastro={entry.gastro}/>

                    <div className="WeinRow-Interface">
                        <ShoppingcartInterface {...entry}/>
                    </div>

                </div>
            </div>
        )
    } else {
        props.removeFromCart(props.vinoId)
        return null
    }


};
