import React, {Component, Fragment} from 'react';
import {WeinImage} from "../Wein/WeinImage";
import {Link} from "react-router-dom";
import {WeinMeta} from "../Wein/WeinMeta";
import {ShoppingcartInterface} from "../ShoppingcartInterface/ShoppingcartInterface";

export const WeinView = props => {
    const {entry} = props;
    return (
        <div className="WeinView container">

            <div className="row">

                <div className="col-12 col-md-4">

                    <div className="WeinView-Image">
                        <WeinImage {...entry} />
                    </div>

                    <div className="WeinView-Links d-none d-md-block">
                        <WeinLinkContainer {...props.entry} />
                    </div>

                </div>


                <div className="col-12 col-md-8">

                    <h2 className="WeinView-Headline">
                        {entry.title}
                    </h2>

                    <div className="WeinView-Meta">
                        <WeinMeta {...entry} />
                    </div>

                    <div className="WeinView-Shopping">
                        <ShoppingcartInterface {...entry} />
                    </div>

                    <div className="WeinView-Beschreibung">
                        <div dangerouslySetInnerHTML={{__html: entry.beschreibung}} className="fliesstext-gross"/>
                    </div>

                    <div className="WeinView-Fakten">
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    props.entry.weinFakten ?
                                        props.entry.weinFakten.map(
                                            (fakt, index) =>
                                                <div key={index} className="FaktenZeile col-12 col-md-6 col-lg-4">
                                                    <div className="FaktenHead aktiv-bold">{fakt.col1}</div>
                                                    <div className="FaktenInfo aktiv-regular">{fakt.col2}</div>
                                                </div>
                                        )
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="WeinView-Links d-md-none">
                        <WeinLinkContainer {...props.entry} />
                    </div>

                </div>
            </div>


        </div>
    );
}


const WeinLinkContainer = props => {
    return (
        <div>
            <Link to={`/${props.bodega[0].uri}`}>
                <div className={"WeinLink-Item"}>
                    <h3>{props.bodega[0].title}</h3>
                    <span className="aktiv-regular">Zum Portrait der Bodega</span>
                </div>
            </Link>
            <Link to={`/${props.region[0].uri}`}>
                <div className={"WeinLink-Item"}>
                    <h3>{props.region[0].title}</h3>
                    <span className="aktiv-regular">Zum Portrait der Region</span>
                </div>
            </Link>
        </div>
    )
}
