import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {IconHeart, IconBag} from "../Icons/Icons";
import {HamburgerIcon} from "../Icons/HamburgerIcon";
import {Transition} from "react-transition-group";


export const MobileNav = props => {

    const [open, setOpen] = useState(false);

    return (
        <Transition in={open} timeout={300}>
            {state =>
                <div className={`MobileNav MobileNav--${state} ${open ? "MobileNav--open" : ""}`}
                     onClick={() => setOpen(!open)}>

                    <div className="MobileNav-Toggler" onClick={() => setOpen(!open)}>
                        <HamburgerIcon active={open}/>
                    </div>

                    <div className="MobileNav-LogoContainer">
                        <img src={window.Craft.logo.url} alt="cava hispania"/>
                    </div>

                    <div className="MobileNav-LinksWrapper">

                        <div className="MobileNav-LinkItem headline-2">
                            <NavLink to={"/"} exact>Home</NavLink>
                        </div>
                        <div className="MobileNav-LinkItem headline-2">
                            <NavLink to={"/sortiment"}>Sortiment</NavLink>
                        </div>
                        <div className="MobileNav-LinkItem headline-2">
                            <NavLink to={"/weinland"}>Weinland</NavLink>
                        </div>

                        <div className="MobileNav-LinkItem headline-2">
                            <NavLink to={"/ueberuns"}>Über uns</NavLink>
                        </div>

                        <div className="MobileNav-LinkItem fliesstext-gross">
                            <NavLink to={"/favoriten"}>
                                <IconHeart/>
                                <span className={"smallCounter aktiv-regular"}>{props.favorites.length}</span>
                            </NavLink>
                            <NavLink to={"/warenkorb"}>
                                <IconBag/>
                                <span className={"smallCounter aktiv-regular"}>{props.cart.length}</span>
                            </NavLink>
                        </div>

                    </div>

                    <div className="MobileNav-LinksWrapperEnd"/>
                </div>
            }
        </Transition>
    );
};